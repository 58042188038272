body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.c-app-root {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: stretch;
	width: 100%;
}

.c-container {
	background-color: white;
	margin: 15px 0 50px 0;
	padding: 0 15px 15px 15px;
	border-radius: 5px;
}
