.c-task-list {
	list-style-type: none;
	padding-left: 0;

	.c-task-list--item {
		border-radius: 0.4rem;

		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
		padding: 5px;

		background-color: $colour-base;

		margin: 20px 15px 20px 15px;

		&.slide-out-right {
			// -webkit-animation: slide-out-right 0.3s
			// 	cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
			// animation: slide-out-right 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

			-webkit-animation: slide-out-blurred-right 300ms
				cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
			animation: slide-out-blurred-right 300ms
				cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
		}

		&.c-task-list--item__complete {
			text-decoration: line-through;
		}

		.c-task-list--item-status {
			display: block;
			flex-grow: 0;
			flex-shrink: 1;
			flex-basis: auto;
			align-self: stretch;
			order: 0;

			width: 9rem;
			margin: -5px 5px -5px -5px;

			.c-task-list--item-status-update {
				height: 100%;
				width: 100%;
				text-align: center;

				// todo tidy these styles up so the focus looks a bit cleaner and isn't focused on one side
				&:focus,
				:hover,
				:focus-visible {
					background-color: $colour-secondary;
					border-radius: 0.4rem 0 0 0.4rem;
				}
			}
		}

		.c-task-list--item-content {
			display: block;
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;
			align-self: stretch;
			order: 0;

			.c-task-list--item-title {
				font-weight: 600;
				line-height: 1rem;

				margin-bottom: 5px;

				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-items: center;
				align-content: center;

				.c-task-list--item-title-items {
					display: block;
					flex-grow: 0;
					flex-shrink: 1;
					flex-basis: auto;
					align-self: auto;
					order: 0;
				}
			}

			.c-task-list--item-description {
				font-size: 0.75rem;
				font-style: italic;

				margin-bottom: 15px;

				.c-task-list--item-description-show-hide {
					float: right;
					border: none;
					background-color: $colour-base;

					// add some padding for fat finger operation
					padding: 4px;

					&.open {
						-webkit-animation: rotate-90-cw 200ms
							cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
						animation: rotate-90-cw 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
							both;
					}

					&.closed {
						-webkit-animation: rotate-90-ccw 200ms
							cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
						animation: rotate-90-ccw 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
							both;
					}
				}
			}
		}
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-28 13:56:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-90-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-ccw {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
}
@keyframes rotate-90-ccw {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-28 14:1:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-90-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-cw {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}
@keyframes rotate-90-cw {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-28 16:51:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
}
@keyframes slide-out-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-28 16:54:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-right {
	0% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
		transform: translateX(1000px) scaleX(2) scaleY(0.2);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}
}
@keyframes slide-out-blurred-right {
	0% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
		transform: translateX(1000px) scaleX(2) scaleY(0.2);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}
}
