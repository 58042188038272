@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;600;700&display=swap');

/**
 * Typography
 * Set base font size
 */

html {
	font-size: $font-size-base;
	font-family: $font-family;
}

p {
	@include body-text-medium;
}
