// 14px
@mixin body-text-small { 
	font-family: $font-family;
	font-size: $font-size-small;
	line-height: $line-height-small;
	font-weight: 400;
}

// 16px
@mixin body-text-medium { 
	font-family: $font-family;
	font-size: $font-size-medium;
	line-height: $line-height-medium;
	font-weight: 400;
}