
.c-header {
	position: sticky;
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: center;

	height: 50px;

	padding: 5px 10px 5px 10px;
	border-bottom: rgb(0, 0, 0) 5px solid;

	-webkit-animation: color-change-2x 10s linear infinite alternate both;
	animation: color-change-2x 10s linear infinite alternate both;

	background-color: rgb(255, 255, 255);
}

.c-header--heading {
    font-size: 1.75rem;
	margin: 0;
	display: block;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	align-self: center;
}

.c-header--nav {
	display: block;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	align-self: auto;
}

// .color-change-2x {
// }

/* ----------------------------------------------
 * Generated by Animista on 2023-10-27 9:42:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
	0% {
		border-color: #19dcea;
	}
	100% {
		border-color: #b22cff;
	}
}
@keyframes color-change-2x {
	0% {
		border-color: #19dcea;
	}
	100% {
		border-color: #b22cff;
	}
}
