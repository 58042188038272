$font-size-base: 16px;
$font-family: 'Comfortaa', sans-serif;

$font-size-small: rem(14px);
$line-height-small: rem(20px);

$font-size-medium: rem(16px);
$line-height-medium: rem(24px);

$font-size-large: rem(20px);
$line-height-large: rem(28px);

$font-size-xlarge: rem(22px);
$line-height-xlarge: rem(30px);

$font-size-xxlarge: rem(24px);
$line-height-xxlarge: rem(32px);

$font-size-xxxlarge: rem(28px);
$line-height-xxxlarge: rem(36px);