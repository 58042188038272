// we have to import this first because it is used in the settings
@import '20-functions/_rem.scss';

// 10-settings
$fa-font-path: '../../node_modules/@fortawesome';
@import '10-settings/typography.scss';
@import '10-settings/spacing.scss';
@import '10-settings/colour.scss';

// 20-functions
@import '20-functions/_colors.scss';
@import '20-functions/_helpers.scss';
@import '20-functions/_layers.scss';
@import '20-functions/_spacing.scss';
@import '20-functions/_strip-unit.scss';
@import '20-functions/_url-encode.scss';

// 30-mixins
@import '30-mixins/typography.scss';

// 40-base
@import '40-base/reset.scss';
@import '40-base/typography.scss';

// 50-components
@import '50-components/task-list.scss';
@import '50-components/header.scss';
@import '50-components/burger-menu.scss';
@import '50-components/pill.scss';
@import '50-components/today-list.scss';

// importing core styling file
@import '../../node_modules/@fortawesome/fontawesome-svg-core';

// 60-utilities

$dark: #212121;

@mixin btn() {
	position: absolute;
	transition-duration: 0.5s;
}

@mixin icon($height, $width) {
	transition-duration: 0.5s;
	position: absolute;
	height: $height;
	width: $width;
	top: 30px;
	background-color: $dark;
}

@mixin icon-before($height, $width, $top) {
	transition-duration: 0.5s;
	position: absolute;
	width: $width;
	height: $height;
	background-color: $dark;
	content: '';
	top: $top;
}

@mixin icon-after($height, $width, $top) {
	transition-duration: 0.5s;
	position: absolute;
	width: $width;
	height: $height;
	background-color: $dark;
	content: '';
	top: $top;
}

@mixin icon-circle($height, $width) {
	transition-duration: 0.5s;
	position: absolute;
	height: $height;
	width: $width;
	top: 2px;
	border-radius: 100%;
	background: transparent;
	border: 4px solid black;
	left: -15px;
}

.btn14 {
	@include btn();

	.icon-circle {
		@include icon-circle(60px, 60px);
		&:hover {
			background: #fff;
		}
	}

	.icon {
		@include icon(4px, 30px);
		border-radius: 4px;

		&:before {
			@include icon-before(4px, 30px, -10px);
			border-radius: 4px;
		}

		&:after {
			@include icon-after(4px, 30px, 10px);
			border-radius: 4px;
		}
	}

	&.open {
		.icon-circle {
			background: #fff;
		}
		.icon {
			transition-duration: 0.5s;
			background: transparent;

			&:before {
				transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
			}

			&:after {
				transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
			}
		}
	}
	&:hover {
		cursor: pointer;
	}
}
